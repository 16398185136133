<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo"> <Logo /></span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import Logo from "@/@core/layouts/components/Logo.vue";
export default {
  components: {
    BLink,
    Logo,
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app;
    return {
      appName,
    };
  },
};
</script>

<style></style>
